import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./react-modal";
import "./MatchDetail.css";

const MatchDetails = ({ match, onClose }) => {
  const [teamBlueData, setTeamBlueData] = useState([]);
  const [teamRedData, setTeamRedData] = useState([]);
  const [selectedOptionTeamBlue, setSelectedOptionTeamBlue] = useState("0");
  const [selectedOptionTeamRed, setSelectedOptionTeamRed] = useState("0");
  const [matchResult, setMatchResult] = useState("");
  const [winner, setWinner] = useState("");
  const [blueTeamScore, setBlueTeamScore] = useState(0);
  const [redTeamScore, setRedTeamScore] = useState(0);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [username, setUsername] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [apiResponseMessage, setApiResponseMessage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true); // Inicializa el botón como desactivado

  // API endpoints for team data
  const teamBlueApiUrl = `https://leagueprodatabackend.vercel.app/api/players/${match.teamBlue}`;
  const teamRedApiUrl = `https://leagueprodatabackend.vercel.app/api/players/${match.teamRed}`;


  useEffect(() => {
    // Add an event listener to update the windowWidth state when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Realizar una solicitud a la API para obtener la información del usuario autenticado
        const authResponse = await axios.post(
          "https://leagueprodatabackend.vercel.app/api/get_session_info/",
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        // Extraer el nombre de usuario de la respuesta
        const authData = authResponse.data;
        setAuthenticated(authData.authenticated);
        setUsername(authData.username);

        // Una vez que se ha obtenido la información de autenticación, puedes establecer loading en falso
      } catch (error) {
        console.error(
          "Error al obtener la información de autenticación o las predicciones: ",
          error
        );
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch data for teamBlue
    axios
      .get(teamBlueApiUrl)
      .then((response) => {
        const teamBluePlayers = response.data;
        // Mapear los jugadores de teamBlue para obtener sus estadísticas
        const playerStatsPromises = teamBluePlayers.map((player) => {
          return axios.get(
            `https://leagueprodatabackend.vercel.app/api/get_player_stats/${player.leaguepedia}`
          );
        });

        // Esperar a que se completen todas las solicitudes de estadísticas
        Promise.all(playerStatsPromises)
          .then((statsResponses) => {
            // Actualizar los datos de teamBlueData con las estadísticas
            const updatedTeamBlueData = teamBluePlayers.map((player, index) => {
              return {
                ...player,
                stats: statsResponses[index].data, // Aquí asumimos que las estadísticas están en response.data
              };
            });
            setTeamBlueData(updatedTeamBlueData);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching player stats for teamBlue:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching teamBlue data:", error);
      });

    // Fetch data for teamRed
    axios
      .get(teamRedApiUrl)
      .then((response) => {
        const teamRedPlayers = response.data;
        // Mapear los jugadores de teamRed para obtener sus estadísticas
        const playerStatsPromises = teamRedPlayers.map((player) => {
          return axios.get(
            `https://leagueprodatabackend.vercel.app/api/get_player_stats/${player.leaguepedia}`
          );
        });

        // Esperar a que se completen todas las solicitudes de estadísticas
        Promise.all(playerStatsPromises)
          .then((statsResponses) => {
            // Actualizar los datos de teamRedData con las estadísticas
            const updatedTeamRedData = teamRedPlayers.map((player, index) => {
              return {
                ...player,
                stats: statsResponses[index].data, // Aquí asumimos que las estadísticas están en response.data
              };
            });
            setTeamRedData(updatedTeamRedData);
          })
          .catch((error) => {
            console.error("Error fetching player stats for teamRed:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching teamRed data:", error);
      });
  }, [teamBlueApiUrl, teamRedApiUrl]);

  useEffect(() => {
    // Parse the matchResult string and calculate the winner and scores
    const [blueScore, redScore] = matchResult
      .split(/\s+/)
      .filter((s) => /^\d+$/.test(s))
      .map(Number);

    if (blueScore > redScore) {
      setWinner(match.teamBlue);
    } else if (redScore > blueScore) {
      setWinner(match.teamRed);
    } else {
      setWinner("?");
    }

    setBlueTeamScore(blueScore);
    setRedTeamScore(redScore);
  }, [matchResult, match.teamBlue, match.teamRed]);

  useEffect(() => {
  
    // Regular expression to extract only the scores from the matchResult
    const scorePattern = /\d+\s*:\s*\d+/;
    const scoreMatch = matchResult.match(scorePattern);
  
    if (scoreMatch) {
      const [blueScore, redScore] = scoreMatch[0]
        .split(":")
        .map((s) => s.trim())  // Trim any extra spaces
        .map(Number);  // Convert to numbers
  
      // Determine the winner based on the score
      if (blueScore > redScore) {
        setWinner(match.teamBlue);
      } else if (redScore > blueScore) {
        setWinner(match.teamRed);
      } else {
        setWinner("?");
      }
  
      // Update the state with the scores
      setBlueTeamScore(blueScore);
      setRedTeamScore(redScore);
  

      // Habilita el botón cuando se cumplan ciertas condiciones
      setIsSaveButtonDisabled(winner === "?" || matchResult === "");
    } else {
      console.error('No valid score found in matchResult');
    }
  }, [matchResult, match.teamBlue, match.teamRed, winner]);


  const filterUniqueRoles = (players) => {
    const uniqueRoles = [];
    const allowedRoles = ["Top", "Jungle", "Mid", "Bot", "Support"]; // Define the allowed roles
    const filteredPlayers = players.filter((player) => {
      if (
        allowedRoles.includes(player.role) &&
        !uniqueRoles.includes(player.role)
      ) {
        uniqueRoles.push(player.role);
        return true;
      }
      return false;
    });
    return filteredPlayers;
  };

  const handleOptionChange = (side, value) => {
    if (side === "blue") {
      setSelectedOptionTeamBlue(value);
      setSelectedOptionTeamRed(value === "1" ? "2" : "1");
    } else if (side === "red") {
      setSelectedOptionTeamRed(value);
      setSelectedOptionTeamBlue(value === "1" ? "2" : "1");
    }
  };

  const handleSavePrediction = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    const predictionData = {
      matchId: match.matchId,
      winnerteam: winner,
      scorered: redTeamScore,
      scoreblue: blueTeamScore,
      username: username,
    };
  
    // Realiza la solicitud POST a la URL /api/save_prediction
    axios
      .post(
        "https://leagueprodatabackend.vercel.app/api/save_prediction/",
        predictionData,
        { headers }
      )
      .then((response) => {
        setApiResponseMessage(response.data.detail);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Error al realizar la solicitud POST:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          setApiResponseMessage(error.response.data.detail);
        } else {
          setApiResponseMessage("An error occurred. Please try again.");
        }
        setShowModal(true);

      });
  };
  
  const handleMatchResultChange = (e) => {
    setMatchResult(e.target.value);
  };

  const renderMatchResultOptions = () => {
    if (match.bo === 1) {
      return (
        <>
          <option
            value={`${match.teamBlue} 1 : 0 ${match.teamRed}`}
          >{`${match.teamBlue} 1 : 0 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 0 : 1 ${match.teamRed}`}
          >{`${match.teamBlue} 0 : 1 ${match.teamRed}`}</option>
        </>
      );
    } else if (match.bo === 3) {
      return (
        <>
          <option
            value={`${match.teamBlue} 2 : 0 ${match.teamRed}`}
          >{`${match.teamBlue} 2 : 0 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 0 : 2 ${match.teamRed}`}
          >{`${match.teamBlue} 0 : 2 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 2 : 1 ${match.teamRed}`}
          >{`${match.teamBlue} 2 : 1 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 1 : 2 ${match.teamRed}`}
          >{`${match.teamBlue} 1 : 2 ${match.teamRed}`}</option>
        </>
      );
    } else if (match.bo === 5) {
      return (
        <>
          <option
            value={`${match.teamBlue} 3 : 0 ${match.teamRed}`}
          >{`${match.teamBlue} 3 : 0 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 0 : 3 ${match.teamRed}`}
          >{`${match.teamBlue} 0 : 3 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 3 : 1 ${match.teamRed}`}
          >{`${match.teamBlue} 3 : 1 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 1 : 3 ${match.teamRed}`}
          >{`${match.teamBlue} 1 : 3 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 3 : 2 ${match.teamRed}`}
          >{`${match.teamBlue} 3 : 2 ${match.teamRed}`}</option>
          <option
            value={`${match.teamBlue} 2 : 3 ${match.teamRed}`}
          >{`${match.teamBlue} 2 : 3 ${match.teamRed}`}</option>
        </>
      );
    }
  };

  const renderTeamTable = (
    teamData: Player[],
    teamName: string,
    colorClass: string
  ) => (
    <div className="team-players-container">
      <div className={`team-title ${colorClass}`}>
        <h3>{teamName}</h3>
      </div>
      <div className="players-table">
        <div className="table-header">
          <div>Player</div>
          <div>Kills</div>
          <div>Deaths</div>
          <div>Assists</div>
          <div>CS/M</div>
          <div>DMG/M</div>
          <div>GOLD/M</div>
        </div>
        {filterUniqueRoles(teamData).map((player) => (
          <div key={player.id} className="table-row">
            <div className="player-info">
              <img
                src={require(`./playerImages/${player.playerName}.webp`)}
                className="player-avatar-pic"
                alt={player.playerName}
              />
              <div>
                <div className="player-name">{player.playerName}</div>
                <div className="player-role">{player.role}</div>
              </div>
            </div>
            <div>{player.stats.Kills}</div>
            <div>{player.stats.Deaths}</div>
            <div>{player.stats.Assists}</div>
            <div>{player.stats.CS_per_min}</div>
            <div>{player.stats.DMG_per_min}</div>
            <div>{player.stats.Gold_per_min}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const LoadingMessage = () => (
    <div className="loader-screen">
      <div className="loading-indicator">
        <div class="dot-spinner">
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="match-details">
      {loading ? (
        <LoadingMessage />
      ) : (
        <>
          <div className="match-header">
            <button className="back-button" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
            </button>
            <h2>Make Your Prediction</h2>
          </div>
          <div className="predict-format">
            <form className="prediction-form">
              <div className="form-blue">
                <div className="team-info">
                  <img
                    src={require(`./teamImages/${match.teamBlue.toUpperCase()}.webp`)}
                    className="team-avatar"
                    alt={match.teamBlue}
                  />
                  <div className="team-name">
                    <h2>{match.teamBlue}</h2>
                  </div>
                </div>
                <div className="team-score">
                  <div className="team-score-points">
                    <h3>{blueTeamScore != null ? blueTeamScore : "?"}</h3>
                  </div>
                </div>
              </div>

              <div className="form-result">
                <select value={matchResult} onChange={handleMatchResultChange}>
                  <option value="" className="option">
                    Select Match Result
                  </option>
                  {renderMatchResultOptions()}
                </select>
              </div>
              <div className="form-red">
                <div className="team-score">
                  <div className="team-score-points">
                    <h3>{redTeamScore != null ? redTeamScore : "?"}</h3>
                  </div>
                </div>
                <div className="team-info">
                  <div className="team-name">
                    <h2>{match.teamRed}</h2>
                  </div>
                  <img
                    src={require(`./teamImages/${match.teamRed.toUpperCase()}.webp`)}
                    className="team-avatar"
                    alt={match.teamRed}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="predict-results">
            <div className="winner-display">
              <span className="winner-label">Winner:</span>
              <span className="winner-team">{winner}</span>
            </div>
            <button
              disabled={isSaveButtonDisabled}
              className={`savepredict ${isSaveButtonDisabled ? "disabled" : ""}`}
              onClick={handleSavePrediction}
            >
              Save prediction
            </button>
          </div>
          <div className="team-players">
            {renderTeamTable(teamBlueData, match.teamBlue, "blue-title")}
            {renderTeamTable(teamRedData, match.teamRed, "red-title")}
          </div>
          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
            message={apiResponseMessage}
          />
        </>
      )}
    </div>
  );
};
export default MatchDetails;

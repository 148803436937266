import React, { useEffect, useRef, useState } from "react";

const Modal = ({ show, onClose, message }) => {
  const modalRef = useRef(null); // Referencia para acceder al modal
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  
  useEffect(() => {
    const calculateModalPosition = () => {
      if (modalRef.current) {
        const modal = modalRef.current;

        // Obtener dimensiones del modal y del viewport
        const modalHeight = modal.offsetHeight;
        const modalWidth = modal.offsetWidth;

        // Calcular posición centrada tomando en cuenta el scroll
        const top = window.scrollY + (window.innerHeight - modalHeight) / 2;

        // Establecer la nueva posición del modal
        setModalPosition({  });
      }
    };

    // Calcular la posición inicial al mostrar el modal
    if (show) {
      calculateModalPosition();
      window.addEventListener("resize", calculateModalPosition);
      window.addEventListener("scroll", calculateModalPosition);
    }

    // Eliminar los listeners cuando el modal se cierra
    return () => {
      window.removeEventListener("resize", calculateModalPosition);
      window.removeEventListener("scroll", calculateModalPosition);
    };
  }, [show]); // Se ejecuta cuando `show` cambia

  const handleClose = () => {
    onClose(); // Cierra el modal
    window.location.reload(); // Refresca la página
  };

  return (
    <div
      className={`login-modal ${show ? "show" : ""}`}
      ref={modalRef}
      style={{
        position: "absolute",
        top: `${modalPosition.top}px`,
        left: `${modalPosition.left}px`,
      }}
    >
      <div className="login-modal-content">
        <h2>{message}</h2>
        <button className="button3 margin-button" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
